import { Link } from "react-router-dom";
import Keys from "../../utils/constants";

const Wrapper = (props) => {
    return (
        <div>
            <div className="col-12 login-header">
                <Link to={'/auth/signin'} className="fs-3 fg-dark bold no-decor">{Keys.APP_NAME}</Link>
                <h6 className='fg-dark mb-1'>{Keys.CO_NAME}</h6>
            </div>
            <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center bg-light">
                { props.children }
            </div>
        </div>
    );
}

export default Wrapper;