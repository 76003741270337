export default class Keys {
    // static USERNAME_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{5,31}$/;
    static USERNAME_REGEX = /^[a-zA-Z][a-zA-Z0-9-_.]{3,31}$/;
    // static EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    static EMAIL_REGEX = /^[a-zA-Z][a-zA-Z0-9-_.]{3,31}$/;
    static OTP_REGEX = /^[0-9]{2,5}$/;
    // static PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&]).{8,255}$/;
    static PASSWORD_REGEX = /^[\p{L}\p{N}\p{M}&.\s-]{3,255}$/u;
    static NAME_REGEX = /^[a-zA-Z0-9äöüňýçşžÄÖÜŇÝÇŞŽ&.\s-]{3,128}$/;
    static PHONE_NUMBER_REGEX = /^[6][1-5][0-9]{6}$/; // starts with 6, second digit in range(1-5), then any number
    static UNSIGNED_DOUBLE_REGEX = /^[0-9.]{0,14}$/;
    static UNSIGNED_INTEGER_REGEX = /^[0-9]{0,100}$/;
    static PHONE_CODE = "+993";

    static ADMINISTRATION_ = "Administration";
    static CP = "CP";

    static IS_DEV = false;
    static UNAUTHORIZED = "Unauthorized";
    static URL_SERVER = this.IS_DEV ? 'http://192.168.1.26:4000' : 'https://ssu.alemtilsimat.com';
    static PATH_PREFIX = this.IS_DEV ? "/static" : "/api/static";
    static PATH_PRODUCT_IMAGES = "/images/";

    static CO_NAME = "Älem Tilsimat";
    static APP_NAME = "SSU";

    static GENERAL = "Umumy";
    static MY_PROFILE = "Profilim";
    static PRIVACY_AND_SECURITY = "Gizlinlik we Howpsuzlyk";
    static USER = "Ulanyjy";
    static SIGN_IN = "Giriş";
    static SIGN_UP = "Hasap aç";
    static HAVE_ACCOUNT = "Hasabyňyz barmy?";
    static HAVE_NOT_ACCOUNT = "Hasabyňyz ýokmy?";
    static USERNAME = "Ulanyjy ady";
    static EMAIL = "Email";
    static PASSWORD = "Açar sözi";
    static CONFIRM_PASSWORD = "Açar sözi gaýtala";
    static YOUR_USERNAME = "Ulanyjy adyňyz";
    static YOUR_PASSWORD = "Açar sözüňiz";
    static FORGOT_PASSWORD = "Açar sözüni unutdym";
    static RESET_PASSWORD = "Açar sözüni täzele";
    static REMAINING_TIME = "Galan wagt:";
    static RESEND_CODE = "Täze kod iber";
    static SUBMIT = "Tassykla";
    static OTP_CODE = "OTP Kod";
    static X_DIGIT_CODE = "6 sanly kod belgisi";
    static ERR_EMPTY_LOGIN_FIELD = "Ulanyjy ady we Açar sözi girizilmedik";
    static ERR_EMPTY_OTP_FIELD = "Kod belgisi girizilmedik";
    // static USERNAME_INSTRUCTION = "6-32 aralygynda simwol. Harp bilen başlamaly. Harplar, sanlar, aşagy çyzgy, we kese çyzgy rugsat berilen.";
    static USERNAME_INSTRUCTION = '3-32 aralygynda simwol. Harp bilen başlamaly. Harplar, sanlar, aşagy çyzgy, we kese çyzgy rugsat berilen.';
    static EMAIL_INSTRUCTION = 'Girizilen E-poçta sintaksisi ýalňyş';
    // static PASSWORD_INSTRUCTION = "8-256 aralygynda simwol. Içinde Baş we setir harplar, san we ýörite simwollar bolmaly. Rugsat berilen ýörite simwollar: !@#$%&";
    static PASSWORD_INSTRUCTION = '3-256 aralygynda simwol rugsat berilen';
    static MATCH_PASSWORD_INSTRUCTION = "Açar sözi - tassyklanan Açar sözi bilen gabat gelmeli."
    static NAME_INSTRUCTION = "Diňe harplar rugsat berilen"
    static PHONE_NUMBER_INSTRUCTION = "8 sandan ybarat bolup, 6-lyk sany bilen başlamaly";
    static OTP_INSTRUCTION = "Kod sanlardan ybarat bolmaly";
    static VERIFY_YOUR_EMAIL = 'E-poçtaňyzy tassyklaň';
    static INVALID_ENTRY = "Ýalňyş girizilen maglumatlar";
    static VERIFIED = "Tassyklanan";
    static NOW = "Şu wagt";
    static ERROR = "Ýalňyşlyk ýüze çykdy";
    static SUCCESS = "Üstünlikli";
    static NO_SERVER_RESPONSE = "Serwerden jogap gelmedi";
    static USERNAME_TAKEN = "Girizen ulanyjy adyňyz öňden alynan";
    static REGISTRATION_FAILED = "Hasap açmakda şowsyz";
    static PAGE_NOT_FOUND = "Sahypa Tapylmady. 404";
    static SOMETHING_WENT_WRONG = "Bir näsazlyk ýüze çykdy";

    static MAIN = "Esasy";
    static REPORTS = "Hasabatlar";
    static REPORT = "Hasabat";
    static STATISTICS = "Statistikalar";
    static ABOUT = "Hakynda";
    static MAIN_PAGE = "Baş sahypa";
    static PROFILE = "Profil";
    static SETTINGS = "Sazlamalar";
    static USERS = "Ulanyjylar";

    static COMMON = "Umumy";
    static ADMIN = "Admin";
    static READ = "Okamak";
    static WRITE = "Ýazmak";
    static UPDATE_ = "Üýtgetmek";
    static DELETE_ = "Pozmak";
    static MODUL = "Modul";

    static ID = "ID";
    static NEXT = "Dowam";
    static GO = "Öňe";
    static BACK = "Yza";
    static FORWARD = "Öňe";
    static DONE = "Tamamlandy";
    static OK = "Bolýar";
    static YES = "Hawa";
    static NO = "Ýok";
    static ADD = "Goş";
    static HELP = "Goldaw";
    static FIND = "Gözle";
    static EXIT = "Çykyş";
    static DETAIL = "Giňişleýin"
    static SHOW = "Görkez";
    static NEW = "Täze";
    static CREATE = "Döret";
    static EDIT = "Üýtget";
    static DELETE = "Poz";
    static COMPLETE = "Tamamla";
    static SAVE = "Ýatda sakla";
    static CANCEL = "Ret et";
    static UPDATE = "Täzele";
    static UPLOAD = "Ýükle";
    static DOWNLOAD = "Indir";
    static NAME = "Ady";
    static DATA_NOT_FOUND = "Maglumat tapylmady";
    static WARNING = "Duýduryş";
    static DANGER = "Howply";
    static CONFIRM = "Tassykla";
    static CONFIRMATION = "Tassyklamak";
    static DEFINITION = "Beýany";
    static ACCOUNT = "Hasap";
    static PROCESSES = "Hereketler";
    static CREATED_DATE = "Döredilen senesi";
    static LAST_UPDATE = "Soňky düzeldiş";
    static TOTAL = "Jemi";

    static DARK_MODE = "Gije mody";
    static LIGHT_MODE = "Gündiz mody";

    static SSU = "Sanly Saglyk Ulgamy";

    static POLICLINCS = "Saglyk öýler";
    static HOSPITALS = "Hassahanalar";
    static E_INJECTION = "E-sanjym";
    static ADMSES = "EGMU";
    static INFLUENCES = "Keseller registeri";
    static WHM = "Ammar dolandyryş";
    static INSURANCE_NB = "Ätiýaçlandyryş depderçesi";
    static E_HEALTH_LIBRARY = "Elektron saglyk kitaphana";
    static EDMS = "Elektron resminama dolandyryş ulgamy";
    static MOBILE_SOLUTION = "Mobil çözgüt";
    static SCIENCE_MODULE = "Ylym modul";
    static HEALTH_ID = "Saglyk ID";
    static HR = "Hünärmen dolandyryş ulgamy";
    static AMS = "Buhgalteriýa";
    static DEVICES = "Enjamlar registri";
    static MKB_10 = "MKB-10";
    static MKB_11 = "MKB-11";
    static TELEMEDICINE = "Telemedisina";
    static HSNP = "SG ulgamyndaky habarlar portaly";
    static BIG_DATA_DS = "BIG-Data maglumat dolandyryş";
    static AI_INFLUENCE_DETECTION_SYSTEM = "AI-kesel anyklaýyş ulgamy";
}