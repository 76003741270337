import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
import AxiosHelper from "../../api/AxiosHelper";
import Keys from "../../utils/constants";
import ToastU from "../../components/ui/ToastU";
import Wrapper from "./Wrapper";

const Login = () => {
    const navigate = useNavigate();
    const { auth, setAuth, ready } = useAuth();
    const emailRef = useRef();
    
    const [email, setEmail] = useState(''); 
    const [password, setPassword] = useState('');

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        if (ready && !auth.token) emailRef.current.focus();
    }, [ready]);

    useEffect(() => {
        setToast({...toast, msg: ''});
    }, [email, password]);

    useEffect(() => {
        if (auth.token == undefined && !auth.token) return;
        if (ready) navigate("/");
    }, [auth, ready]);

    const handleLoginSubmit = (event) => {
        event.preventDefault();

        const data = { email, password }
        AxiosHelper.login(data).then((res) => {
            if (res.status == 200) {
                setAuth(res.data);
                navigate("/");
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: Keys.NO_SERVER_RESPONSE, variant: 'danger', title: Keys.ERROR, show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: Keys.ERROR, show: true });
        });
    }

    return (
        <div>
            {(!ready && auth.token == Keys.UNAUTHORIZED) && <Wrapper>
                <span className="text-center fs-3 bold">{Keys.SIGN_IN}</span>
                <div className="container col-md-3 border rounded-3 p-4 bg-white">
                    <ToastU toast={toast} setToast={setToast}/>
                    <form onSubmit={handleLoginSubmit} noValidate>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label text-dark bold">{Keys.USERNAME}</label>
                            <input id="email"
                                ref={emailRef}
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="form-control form-control-sm bg-light"
                                placeholder={Keys.USERNAME}
                                autoComplete="off"
                                required/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label text-dark bold">{Keys.PASSWORD}</label>
                            <input id="password"
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="form-control form-control-sm bg-light"
                                placeholder={Keys.YOUR_PASSWORD}
                                required/>
                        </div>
                        <button type="submit"
                            className="btn btn-dark col-12 mb-3"
                            disabled={(!email || !password) ? true : false}>
                            {Keys.SIGN_IN}
                        </button>
                    </form>
                </div>
            </Wrapper>}
        </div>
    );
};

export default Login;