import Keys from "../utils/constants";
import icEmojiSad from "../assets/svg/ic_emoji_sad.svg"

const PageNotFound = ({...props}) => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center m-5 p-5">
            <span className="bold fs-2 mb-2">{Keys.SOMETHING_WENT_WRONG}</span>
            <img src={icEmojiSad} className="image-product" alt="emoji sad"/>
        </div>
    );
}

export default PageNotFound;