import { Link, useNavigate } from "react-router-dom";

const ModuleItem = ({...props}) => {
    const navigate = useNavigate();

    const item = props.item;

    return (
        <div className="dashboard-btn">
            <a href={item.link} className={`icon ${item.className}`}>
                <img src={item.icon} alt={item.name} className="wh-50"/>
            </a>
            <span className="medium font-12 text-center fg-dark mt-2">{item.name}</span>
        </div>
    );
}

export default ModuleItem;