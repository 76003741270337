import Keys from "../../utils/constants";
import Wrapper from "../../layouts/Wrapper";
import ModuleItem from "./components/ModuleItem";
import ic1 from '../../assets/svg/ic_1.svg';
import ic2 from '../../assets/svg/ic_2.svg';
import ic3 from '../../assets/svg/ic_3.svg';
import ic4 from '../../assets/svg/ic_4.svg';
import ic5 from '../../assets/svg/ic_5.svg';
import ic6 from '../../assets/svg/ic_6.svg';
import ic7 from '../../assets/svg/ic_7.svg';
import ic8 from '../../assets/svg/ic_8.svg';
import ic9 from '../../assets/svg/ic_9.svg';
import ic10 from '../../assets/svg/ic_10.svg';
import ic11 from '../../assets/svg/ic_11.svg';
import ic12 from '../../assets/svg/ic_12.svg';
import ic13 from '../../assets/svg/ic_13.svg';
import ic14 from '../../assets/svg/ic_14.svg';
import ic15 from '../../assets/svg/ic_15.svg';
import ic16 from '../../assets/svg/ic_16.svg';
import ic17 from '../../assets/svg/ic_17.svg';
import ic18 from '../../assets/svg/ic_18.svg';
import ic19 from '../../assets/svg/ic_19.svg';
import ic20 from '../../assets/svg/ic_20.svg';
import ic21 from '../../assets/svg/ic_21.svg';


const items = [
    { name: Keys.POLICLINCS, icon: ic1, link: '/'},
    { name: Keys.HOSPITALS, icon: ic2, link: 'https://fiz2.alemtilsimat.com'},
    { name: Keys.E_INJECTION, icon: ic3, link: '/'},
    { name: Keys.ADMSES, icon: ic4, link: 'https://egmu.gov.tm'},
    { name: Keys.INFLUENCES, icon: ic5, link: '/'},
    { name: Keys.WHM, icon: ic6, link: '/'},
    { name: Keys.INSURANCE_NB, icon: ic7, link: '/'},
    { name: Keys.E_HEALTH_LIBRARY, icon: ic8, link: '/'},
    { name: Keys.EDMS, icon: ic9, link: '/'},
    { name: Keys.MOBILE_SOLUTION, icon: ic10, link: '/'},
    { name: Keys.SCIENCE_MODULE, icon: ic11, link: '/'},
    { name: Keys.HEALTH_ID, icon: ic12, link: '/'},
    { name: Keys.HR, icon: ic13, link: '/'},
    { name: Keys.AMS, icon: ic14, link: 'https://alemhasap.alemtilsimat.com'},
    { name: Keys.DEVICES, icon: ic15, link: '/'},
    { name: Keys.MKB_10, icon: ic16, link: '/'},
    { name: Keys.MKB_11, icon: ic17, link: '/'},
    { name: Keys.TELEMEDICINE, icon: ic18, link: '/'},
    { name: Keys.HSNP, icon: ic19, link: '/'},
    { name: Keys.BIG_DATA_DS, icon: ic20, link: '/'},
    { name: Keys.AI_INFLUENCE_DETECTION_SYSTEM, icon: ic21, link: '/'},
]

const Dashboard = ({...props}) => {
    return (
        <Wrapper loading={props.loading}>
            <div className="main-page flex-column dashboard-bg vh-100">
                <span className="fs-1 bold mx-3 mb-3 fg-dark">{Keys.SSU}</span>
                <div className="d-flex flex-column align-items-center col-md-12">
                    <div className="d-flex flex-row">
                       {items.slice(0, 6).map((item) => { return ( <ModuleItem key={item.name} item={item}/>) })}
                    </div>
                    <div className="d-flex flex-row">
                       {items.slice(6, 12).map((item) => { return ( <ModuleItem key={item.name} item={item}/>) })}
                    </div>
                    <div className="d-flex flex-row">
                       {items.slice(12, 18).map((item) => { return ( <ModuleItem key={item.name} item={item}/>) })}
                    </div>
                    <div className="d-flex flex-row">
                       {items.slice(18, 24).map((item) => { return ( <ModuleItem key={item.name} item={item}/>) })}
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

export default Dashboard;