import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Keys from "../utils/constants";
import TaskBar from "./TaskBar";

const Wrapper = ({...props}) => {
    const navigate = useNavigate();
    const { auth, ready } = useAuth();

    useEffect(() => {
        if (auth.token == undefined && !auth.token) return;
        else if (auth.token == undefined || auth.token == Keys.UNAUTHORIZED) {
            navigate('/auth/signin');
        }
    }, [auth]);

    return (
        ready && <div className="d-flex flex-column">
            {/* <TaskBar loading={props.loading}/> */}
            { props.children }
        </div>
    );
}

export default Wrapper;