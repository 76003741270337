import axios from 'axios';
import Keys from '../utils/constants';

const baseURL = `${Keys.URL_SERVER}/api`;

const instance = axios.create({
    baseURL,
    headers: { Accept: 'application/json'},
    withCredentials: true,
});

class AxiosHelper {

    static async login(data) {
        return await instance.post('/auth/signin', data);
    }

    static async signOut() {
        return await instance.post('/auth/signout');
    }

    static async signUp(data) {
        return await instance.post('/auth/signup', data);
    }

    static async refresh() {
        return await instance.get('/auth/refresh');
    }
}

export default AxiosHelper;