import { useEffect } from 'react';
import useAuth from './hooks/useAuth';
import useRefreshToken from './hooks/useRefreshToken';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import PageNotFound from './pages/PageNotFound';
import SomethinWentWrong from './pages/SomethingWentWrong';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard';

function App() {
  const { auth } = useAuth();
  const refresh = useRefreshToken();

  useEffect(() => {
    refresh();
  }, [auth.token]);
  
  return (
    <BrowserRouter>
      <Routes>
        {/* public routes */}
        <Route path='/something-went-wrong' element={<SomethinWentWrong />} />
        <Route path='/auth/signin' element={<Login />} />

        <Route path='/' element={<Dashboard/>} />

        {/* catch all */}
        <Route path='/page-not-found' element={<PageNotFound />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;